@import url(https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Source+Sans+3:wght@400;600&family=Material+Icons&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    color-scheme: dark;
    
    --conceptcolor: hsl(151, 80%, 45%);
    --feedcolor: hsl(186, 80%, 50%);
    --sourcecolor: hsl(271, 80%, 75%);
    --authorcolor: hsl(27, 80%, 75%);
    --white: hsl(0, 0%, 85%);
    --subwhite: hsl(0, 0%, 65%);
    
    --font0: 'Source Sans 3', sans-serif;
    --logofont: 'Inter', sans-serif;
    --bgcolor: #000000;
    --borderradius0: 20px;
    --borderradius1: 10px;
    --interfaceopacity: 0.9;
    --interfacebackdropblur: 15px;
    --titlecolor1: var(--feedcolor);
    --titlecolor2: var(--white);
    --searchbarcolor: #303030;
    --emailcolor: var(--white);
    --searchresultbgcolor: #101010;
    --searchresultbghovercolor: #202020;
    --searchresultcolor: var(--white);
    --searchresultbordercolor: #202020;
    --sidebar-open-width: 300px;
    --sidebar-current-width: 0px;
    --sidebar-transition-duration: 100ms;
    --sidebar-transition-timing-function: ease;
    --middle-grid-sidebar-space-width: var(--sidebar-current-width);
    --textcolor: var(--white);
    overflow-x: hidden;
    font-size: 1.1em;
}

@media (max-width: 900px) {
    :root {
        --middle-grid-sidebar-space-width: 0px;
    }
}

:root.root-sidebar-open {
    --sidebar-current-width: var(--sidebar-open-width);
}

a:link, a:visited, a:hover, .fake-link {
    color: hsl(186, 80%, 50%);
    color: var(--feedcolor);
    text-decoration: underline;
    cursor: pointer;
}

html, body, #root, .signed-in-view, .main, .centered-div-container {
    width: 100vw;
    min-height: 100vh;
    margin: 0;
    padding: 0;
}

body {
    background-color: #000000;
    background-color: var(--bgcolor);
    -ms-scroll-chaining: none;
        overscroll-behavior: none;
}

.alert-view {
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 2;
    justify-content: center;
    align-items: center;
}

.alert-box {
    box-sizing: border-box;
    width: min(600px, 100% - 30px);
    padding: 20px;
    margin: auto;
    background-color: #080808;
    opacity: 1;
    border-radius: 20px;
    border-radius: var(--borderradius0);
    border: 1px solid #101010;
}

.alert-buttons {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    margin-top: 25px;
}

.alert-button {
    width: 130px;
    min-height: 40px;
    border-radius: 20px;
    border-radius: var(--borderradius0);
    border-style: none;
    font-size: 0.9em;
}

.alert-button-primary {
    background-color: hsl(186, 80%, 50%);
    background-color: var(--feedcolor);
    color: #000000;
    font-weight: 600;
}

.alert-button-secondary {
    background-color: #181818;
    color: hsl(0, 0%, 85%);
    color: var(--white);
    font-weight: 400;
}

.main {
    display: grid;
    grid-template-rows: 51px 1fr;
}

.centered-div-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.centered-div {
    padding: 30px;
    width: 300px;
    background-color: #101010;
    border-radius: 20px;
    border-radius: var(--borderradius0);
}

.zatemy-logo {
    background: linear-gradient(
        to right,
        hsl(186, 80%, 50%),
        hsl(151, 80%, 45%));
    background: linear-gradient(
        to right,
        var(--feedcolor),
        var(--conceptcolor)
    );
    font-family: 'Inter', sans-serif;
    font-family: var(--logofont);
    -webkit-background-clip: text;
            background-clip: text;
    font-weight: 600;
    color: transparent;
}

header {
    --menu-button-width: 55px;
    --header-logo-width: 176px;
    --header-left-width: max(calc(var(--menu-button-width) + var(--header-logo-width)), var(--sidebar-current-width));
    display: flex;
    position: fixed;
    grid-template-columns: var(--menu-button-width) calc(var(--header-left-width) - var(--menu-button-width)) 1fr;
    grid-column-gap: 0;
    -webkit-column-gap: 0;
            column-gap: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: rgba(24, 24, 24, 0.9);
    background-color: rgba(24, 24, 24, var(--interfaceopacity));
    -webkit-backdrop-filter: blur(15px);
            backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(var(--interfacebackdropblur));
            backdrop-filter: blur(var(--interfacebackdropblur));
    border-bottom: 1px solid #202020;
    z-index: 1;
}

@media (max-width: 1300px) {
    header {
        --header-left-width: max(var(--menu-button-width), var(--sidebar-current-width));
    }
}

.menu-button {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--menu-button-width);
    font-family: 'Material Icons';
    font-size: 25pt;
    line-height: 50px;
    color: hsl(0, 0%, 85%);
    color: var(--titlecolor2);
    cursor: pointer;
}

.header-logo-container {
    box-sizing: border-box;
    margin: 0;
    width: calc(var(--header-left-width) - var(--menu-button-width));
    height: 100%;
    font-size: 20pt;
    line-height: 50px;
    overflow: hidden;
    transition: width 100ms;
    transition: width var(--sidebar-transition-duration);
    transition-timing-function: ease;
    transition-timing-function: var(--sidebar-transition-timing-function);
}

.header-logo-container > .zatemy-logo {
    cursor: pointer;
}

@media (max-width: 500px) {
    .header-logo-container {
        width: 0;
    }
}

.title-link {
    text-decoration: none;
}

.header-interface {
    --menu-button-middle-content-horizontal-gap: calc(
        ((100vw - var(--sidebar-current-width) - 800px) / 2)
        + var(--sidebar-current-width)
        - var(--header-left-width)
    );
    --header-interface-left-width: var(--menu-button-middle-content-horizontal-gap);
    display: flex;
    padding: 0 15px 0 0;
    flex-grow: 1;
}

.root-sidebar-open .header-interface {
    --header-interface-left-width: max(15px, var(--menu-button-middle-content-horizontal-gap));
}

@media (max-width: 800px) {
    .header-interface {
        --header-interface-left-width: 0;
    }

    .root-sidebar-open .header-interface {
        --header-interface-left-width: 0;
    }
}

.header-interface-left {
    width: var(--header-interface-left-width);
    min-width: -webkit-min-content;
    min-width: min-content;
    transition: width 100ms;
    transition: width var(--sidebar-transition-duration);
    transition-timing-function: ease;
    transition-timing-function: var(--sidebar-transition-timing-function);
}

.back-button {
    margin-left: 5px;
    width: 40px;
    font-family: 'Material Icons';
    font-size: 20pt;
    line-height: 50px;
    color: hsl(0, 0%, 85%);
    color: var(--titlecolor2);
    cursor: pointer;
}

.header-interface-right {
    display: flex;
    flex-grow: 1;
}

.header-interface-search {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0;
    flex-grow: 1;
}

.search-bar-placeholder {
    position: relative;
    width: min(100%, 800px);
    height: 33.5px;
}

.search-bar {
    display: grid;
    position: absolute;
    width: 100%;
    min-height: 100%;
    grid-template-columns: 1fr 35px;
    border: 0;
    border-radius: 20px;
    border-radius: var(--borderradius0);
    background-color: #303030;
    overflow: hidden;
}

.search-bar-focused {
    left: -1px;
    top: -1px;
    border: 1px solid hsl(186, 80%, 50%);
    border: 1px solid var(--feedcolor);
}

.search-bar-content {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    padding: 5px 0 5px 10px;
    min-height: 33.5px;
}

.search-selected-item {
    display: flex;
    height: 23.5px;
    align-items: center;
}

.search-input {
    flex-grow: 1;
    margin: 0;
    padding: 0;
    height: 23.5px;
    width: 0;
    min-width: 15px;
    border: none;
    outline: none;
    background-color: transparent;
    line-height: 23.5px;
    color: hsl(0, 0%, 85%);
    color: var(--white);
    font-family: 'Source Sans 3', sans-serif;
    font-family: var(--font0);
    font-size: 1em;
}

.search-button {
    padding: 0;
    background: transparent;
    font-family: 'Material Icons';
    font-size: 20pt;
    color: hsl(186, 80%, 50%);
    color: var(--feedcolor);
    border: 0;
    border-radius: 20px;
    border-radius: var(--borderradius0);
    cursor: pointer;
}

.search-suggestions {
    grid-column: 1 / 3;
    background-color: #181818;
}

.search-suggestion {
    padding: 10px;
    cursor: pointer;
}

.search-suggestion:hover, .search-suggestion:active {
    background-color: #303030;
}

.user-info {
    box-sizing: border-box;
    padding: 0 0 0 10px;
    flex-grow: 0;
    text-align: right;
    font-size: 14pt;
    color: hsl(0, 0%, 85%);
    color: var(--emailcolor);
    line-height: 50px;
    font-family: 'Source Sans 3', sans-serif;
    font-family: var(--font0);
    overflow: hidden;
}

@media (max-width: 1300px) {
    .user-info {
        width: 0;
        padding: 0;
    }
}

.specific-feed {
    display: flex;
    flex-direction: column;
    min-height: 0;
    height: 100%;
    transition: width 100ms;
    transition: width var(--sidebar-transition-duration);
    transition-timing-function: ease;
    transition-timing-function: var(--sidebar-transition-timing-function);
}

.info-bar {
    box-sizing: border-box;
    flex-shrink: 0;
    display: flex;
    padding: 10px 15px 9px 15px;
    width: 100%;
    background-color: #101010;
    border-bottom: 1px solid #202020;
}

.info-bar-left, .info-bar-right {
    margin: 0;
    width: calc(50% - 400px);
}

.info-bar-center {
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 0;
    -webkit-column-gap: 0;
            column-gap: 0;
    grid-row-gap: 15px;
    row-gap: 15px;
    width: min(100%, 800px);
    margin: 0;
    padding: 0;
    color: hsl(0, 0%, 85%);
    color: var(--textcolor);
    font-family: 'Source Sans 3', sans-serif;
    font-family: var(--font0);
}

.info-bar-center-left {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    flex-grow: 1;
    font-size: 1.2em;
    line-height: 30px;
}

.result-descriptor {
    font-weight: 800;
}

.info-bar-center-right {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 5px;
    gap: 5px;
    text-align: right;
}

.info-bar-tool-button {
    margin-right: 5px;
    font-family: 'Material Icons';
    font-size: 20pt;
    line-height: 30px;
    cursor: pointer;
}

.info-bar-tool-button-active {
    color: hsl(186, 80%, 50%);
    color: var(--feedcolor);
}

.info-bar-button {
    box-sizing: border-box;
    width: 100px;
    height: 30px;
    border-radius: 20px;
    border-radius: var(--borderradius0);
    border-style: none;
    font-size: 0.85em;
    font-weight: 600;
    color: #000000;
    color: var(--bgcolor);
}

.info-bar-button-up {
    background-color: transparent;
    color: hsl(186, 80%, 50%);
    color: var(--feedcolor);
    border: 1px solid hsl(186, 80%, 50%);
    border: 1px solid var(--feedcolor);
}

.info-bar-button-down {
    background-color: hsl(186, 80%, 50%);
    background-color: var(--feedcolor);
    color: #000000;
}

.middle {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    min-height: 0;
}

.middle-grid {
    --middle-content-width: calc(100vw - var(--middle-grid-sidebar-space-width));
    display: flex;
    margin: 0;
    padding: 0;
    min-height: 0;
}

.sidebar-space {
    width: 0px;
    width: var(--middle-grid-sidebar-space-width);
    height: 100%;
    transition: width 100ms;
    transition: width var(--sidebar-transition-duration);
    transition-timing-function: ease;
    transition-timing-function: var(--sidebar-transition-timing-function);
}

.middle-content {
    --middle-content-side-padding: 15px;
    --item-border-radius: var(--borderradius0);
    box-sizing: border-box;
    width: var(--middle-content-width);
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-direction: column;
    z-index: 0;
    flex-grow: 1;
    transition: width 100ms;
    transition: width var(--sidebar-transition-duration);
    transition-timing-function: ease;
    transition-timing-function: var(--sidebar-transition-timing-function);
}

.middle-content-banner {
    position: relative;
    box-sizing: border-box;
    margin: 20px auto;
    padding: 15px;
    width: min(100%, 800px);
    text-align: center;
    color: hsl(0, 0%, 85%);
    color: var(--white);
    font-family: 'Source Sans 3', sans-serif;
    font-family: var(--font0);
    font-size: 1em;
    background-color: #101010;
    border: 1px solid #202020;
    border-radius: 20px;
    border-radius: var(--borderradius0);
}

@media (max-width: 800px) {
    .middle-content-banner {
        border-width: 1px 0;
        border-radius: 0;
    }
}

.middle-content-padding {
    padding: 0 var(--middle-content-side-padding) var(--middle-content-side-padding) var(--middle-content-side-padding);
}

.search-results {
    box-sizing: border-box;
    margin: 0 auto 0 auto;
    width: min(100%, 800px);
}

.search-result:hover {
    background-color: #202020;
    background-color: var(--searchresultbghovercolor);
    cursor: pointer;
}

.tag {
    font-weight: 600;
}

.source-tag {
    color: hsl(271, 80%, 75%);
    color: var(--sourcecolor);
}

.topic-tag {
    color: hsl(151, 80%, 45%);
    color: var(--conceptcolor);
}

.feed-tag {
    color: hsl(186, 80%, 50%);
    color: var(--feedcolor);
}

.author-tag {
    color: hsl(27, 80%, 75%);
    color: var(--authorcolor);
}

.tag-punct {
    color: hsl(0, 0%, 85%);
    color: var(--white);
}

.sidebar-container {
    position: fixed;
    width: 100%;
    display: flex;
    flex-direction: row;
    top: 51px;
    bottom: 0;
    z-index: 1;
    touch-action: none;
    pointer-events: none;
}

.sidebar {
    width: 0px;
    width: var(--sidebar-current-width);
    background-color: #101010;
    overflow-x: hidden;
    flex-grow: 0;
    pointer-events: initial;
    transition: width 100ms;
    transition: width var(--sidebar-transition-duration);
    transition-timing-function: ease;
    transition-timing-function: var(--sidebar-transition-timing-function);
}

.sidebar-content-cover {
    display: none;
    flex-grow: 1;
}

@media (max-width: 800px) {
    .root-sidebar-open .sidebar-content-cover {
        display: inline;
        display: initial;
        pointer-events: initial;
    }
}

.sidebar-content {
    box-sizing: border-box;
    padding: 10px 15px 15px 15px;
    --width: var(--sidebar-open-width);
    width: var(--width);
    min-width: var(--width);
    min-height: 100%;
    border-right: 1px solid #202020;
}

.sidebar-section-heading {
    margin: 0;
    padding: 5px 0 5px 0;
    font-family: 'Source Sans 3', sans-serif;
    font-family: var(--font0);
    font-weight: 600;
}

.sidebar-section-content {
    margin-left: 20px;
}

.news-sidebar-section-content {
    margin-left: 10px;
}

.sidebar-item {
    margin: 0;
    padding: 5px 0 5px 0;
    font-family: 'Source Sans 3', sans-serif;
    font-family: var(--font0);
}

.query-sidebar-item {
    box-sizing: border-box;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #282828;
    border-radius: 10px;
    border-radius: var(--borderradius1);
    background-color: #202020;
    cursor: pointer;
}

.query-sidebar-item:first-of-type {
    margin-top: 5px;
}

.query-sidebar-item:last-of-type {
    margin-bottom: 5px;
}

.query-sidebar-item:hover {
    background-color: #282828;
}

.query-sidebar-item, .sidebar-item .tag-punct {
    color: hsl(0, 0%, 65%);
    color: var(--subwhite);
}

.query-label-part {
    display: inline-block;
}

.query-type-label {
    display: inline-block;
    margin-top: 5px;
    padding: 2px 5px;
    background-color: #303030;
    border: 1px solid #383838;
    border-radius: 5px;
}

.clickable:hover {
    text-decoration: underline;
    cursor: pointer;
}

.subfeed {
    grid-column: 1 / -1;
    box-sizing: border-box;
    background-color: #101010;
    border-radius: var(--item-border-radius);
    border: 1px solid #202020;
    color: hsl(0, 0%, 85%);
    color: var(--textcolor);
    font-family: 'Source Sans 3', sans-serif;
    font-family: var(--font0);
}

@media (max-width: 400px) {
    .middle-content {
        --middle-content-side-padding: 0;
        --item-border-radius: 0;
    }

    .subfeed {
        max-width: 100vw; /* This prevents an issue in iOS Safari where .grid-items that are part of a .subfeed sometimes become very wide. */
        border-left: 0;
        border-right: 0;
    }

    .vertical-grid > .grid-item {
        border-radius: 0;
    }
}

.subfeed-label {
    margin: 15px 15px 0 15px;
    cursor: pointer;
}

.subfeed-label > .query-label, .subfeed-label .tag-punct {
    color: hsl(0, 0%, 65%);
    color: var(--subwhite);
}

.vertical-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    gap: 15px;
    flex-wrap: wrap;
    margin-top: 15px;
    margin-left: max(
        calc((var(--middle-content-width) - 800px) / 2 - var(--middle-content-side-padding)),
        0px);
    max-width: 800px;
    height: 100%;
    transition: margin-left 100ms;
    transition: margin-left var(--sidebar-transition-duration);
    transition-timing-function: ease;
    transition-timing-function: var(--sidebar-transition-timing-function);
}

.middle-content-padding-after-message .vertical-grid {
    margin-top: 0;
}

.horizontal-grid {
    display: grid;
    padding: 15px;
    grid-auto-columns: calc(50% - 30px);
    grid-auto-flow: column;
}

.infinitely-scrollable-horizontal-wrapper {
    overflow-x: auto;
}

.grid-end-item {
    width: 0;
    height: 0;
}

.horizontal-grid > .grid-end-item {
    width: 15px;
    height: 100%;
}

.grid-item {
    --edgepadding: 30px;
    position: relative;
    box-sizing: border-box;
    display: grid;
    grid-template-rows: clamp(100px, 25vw, 250px) 1fr;
    background-color: #181818;
    border-radius: 10px;
    border-radius: var(--borderradius1);
    border: 1px solid #282828;
    overflow: hidden;
    text-decoration: none;
}

.horizontal-grid > .grid-item {
    margin-right: 15px;
}

.horizontal-grid > .grid-item:nth-last-child(2) {
    margin-right: 0;
}

@media (max-width: 400px) {
    .vertical-grid > .grid-item {
        border-width: 1px 0 1px 0;
    }
}

.grid-item-img-div {
    position: relative;
    width: 100%;
}

.grid-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.grid-img-overlay {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
}

.grid-img-fade {
    flex: 1 1;
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0.25),
        rgba(0, 0, 0, 0.475),
        rgba(0, 0, 0, 0.75)
    );
}

.grid-img-title {
    --betweenpadding: calc(var(--edgepadding) / 2);
    padding: 0 var(--edgepadding) var(--betweenpadding) var(--edgepadding);
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.75),
        rgba(12, 12, 12, 1)
    );
    text-shadow: 0 2px 0 black;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    font-size: 1em;
    font-weight: 600;
    text-decoration: none;
    color: hsl(0, 0%, 85%);
    color: var(--textcolor);
    --title-line-height: 1.4em;
    line-height: var(--title-line-height);
}

.grid-item-text-div {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-gap: 15px;
    gap: 15px;
    padding: 0 var(--edgepadding) var(--edgepadding) var(--edgepadding);
    font-size: 0.95em;
    font-family: 'Source Sans 3', sans-serif;
    font-family: var(--font0);
    color: hsl(0, 0%, 85%);
    color: var(--textcolor);
    background: linear-gradient(
        to bottom,
        #0c0c0c,
        #181818
    );
}

.horizontal-grid .grid-item-title-div {
    --title-lines: 3;
    height: calc(var(--title-lines) * var(--title-line-height));
    -webkit-line-clamp: var(--title-lines);
    overflow: hidden;
}

.grid-item-details-div .tag {
    font-weight: 400;
}

.grid-item-details-div {
    display: flex;
    max-width: 100%;
    flex-direction: column;
    grid-gap: 0.25em;
    gap: 0.25em;
    opacity: 0.85;
    overflow: hidden;
    font-size: 0.875em;
}

.grid-item-authors-div {
    flex-grow: 1;
}

.grid-item-authors-div .tag-punct {
    color: hsl(0, 0%, 65%);
    color: var(--subwhite);
}

.grid-item-authors-div, .grid-item-src-div, .grid-item-time-div {
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.grid-item-subinfo {
    display: flex;
    flex-wrap: nowrap;
    max-width: 100%;
    color: hsl(0, 0%, 65%);
    color: var(--subwhite);
}

.grid-item-time-div {
    flex-grow: 1;
}

@media (max-width: 600px) {
    .vertical-grid {
        grid-template-columns: 1fr;
    }

    .horizontal-grid {
        grid-auto-columns: calc(100% - 30px);
    }
    
    .grid-item {
        grid-template-rows: clamp(200px, 50vw, 400px) 1fr;
    }

    .grid-item-text-div {
        font-size: clamp(14px, 4vw, 20px);
    }
}

.no-articles {
    box-sizing: border-box;
    margin: 0 auto 0 auto;
    padding: 20px;
    width: min(100%, 800px);
    text-align: center;
    color: hsl(0, 0%, 85%);
    color: var(--white);
    font-family: 'Source Sans 3', sans-serif;
    font-family: var(--font0);
    font-size: 1.1em;
}

.info-page {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    grid-row-gap: 30px;
    row-gap: 30px;
    margin: 0 auto 0 auto;
    padding: 20px;
    width: min(100%, 800px);
    height: 100%;
    background-color: #101010;
    border-left: 1px solid #202020;
    border-right: 1px solid #202020;
}

.info-centered {
    align-items: center;
    text-align: center;
}

.info-title {
    margin: 0 0 10px 0;
    color: hsl(0, 0%, 85%);
    color: var(--white);
    font-family: 'Source Sans 3', sans-serif;
    font-family: var(--font0);
    font-size: 1.2em;
}

.info-subtitle {
    font-weight: 600;
}

.info-text {
    color: hsl(0, 0%, 85%);
    color: var(--white);
    font-family: 'Source Sans 3', sans-serif;
    font-family: var(--font0);
    font-size: 1em;
    margin: 0;
}

p.info-text + p.info-text {
    margin-top: 1em;
}

.info-text li {
    margin-bottom: 10px;
}

.info-text-small {
    font-size: 0.9em;
}

.info-no-margin-below {
    margin-bottom: 0;
}

.tutorial-title {
    margin-top: 20px;
}

.plans-title {
    margin-bottom: 15px;
    text-align: center;
    color: hsl(0, 0%, 85%);
    color: var(--white);
    font-family: 'Source Sans 3', sans-serif;
    font-family: var(--font0);
    font-size: 1.5em;
}

.plans {
    margin-top: 15px;
    width: 100%;
    display: flex;
    grid-gap: 15px;
    gap: 15px;
}

@media (max-width: 600px) {
    .plans {
        flex-direction: column;
    }
}

.plan {
    box-sizing: border-box;
    padding: 30px;
    flex: 1 1;
    background-color: #181818;
    border-radius: 10px;
    border-radius: var(--borderradius1);
    border: 1px solid #282828;
}

.plan-section {
    display: flex;
    flex-direction: column;
    grid-gap: 30px;
    gap: 30px;
}

.plan-title {
    text-align: center;
    font-size: 1.2em;
    font-weight: 600;
    color: hsl(186, 80%, 50%);
    color: var(--feedcolor);
}

.plan-price {
    margin: auto;
    height: 30px;
    display: flex;
    flex-wrap: nowrap;
}

.plan-price-number {
    text-align: center;
    font-size: 1.8em;
    font-weight: 600;
    line-height: 30px;
}

.plan-text {
    margin: auto;
    text-align: center;
}

.plan-button {
    margin: auto;
    width: min(130px, 100%);
    height: 40px;
    border-radius: 20px;
    border-radius: var(--borderradius0);
    border-style: none;
    font-size: 0.9em;
    cursor: pointer;
}

.plan-button-primary {
    background-color: hsl(186, 80%, 50%);
    background-color: var(--feedcolor);
    color: #000000;
    font-weight: 600;
}

.plan-bottom-text {
    margin: auto;
    text-align: center;
    color: hsl(0, 0%, 65%);
    color: var(--subwhite);
}

.help-title {
    margin: 0 0 10px 0;
    text-align: center;
    color: hsl(0, 0%, 85%);
    color: var(--white);
    font-family: 'Source Sans 3', sans-serif;
    font-family: var(--font0);
    font-size: 1.2em;
}

.help-entry {
    font-size: 1.2em;
    line-height: 30px;
}

.help-subentry {
    margin: 10px 0 10px 20px;
    font-size: 1.2em;
    line-height: 30px;
}

.help-icons-font {
    font-family: 'Material Icons';
    line-height: 20px;
}

.fake-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.form {
    display: grid;
    grid-template-columns: 1fr;
    width: 300px;
}

.form-field {
    display: flex;
    flex-direction: column;
    width: 300px;
}

.form-field-checkbox {
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
}

.form-field-label {
    margin: 0 0 5px 0;
    color: hsl(0, 0%, 85%);
    color: var(--white);
    font-family: 'Source Sans 3', sans-serif;
    font-family: var(--font0);
    font-size: 1em;
}

.form-input {
    box-sizing: border-box;
    height: 30px;
    margin: 0 0 10px 0;
    padding: 5px;
    border: 0;
    border-radius: 20px;
    border-radius: var(--borderradius0);
    background-color: #303030;
    color: hsl(0, 0%, 85%);
    color: var(--white);
    font-family: 'Source Sans 3', sans-serif;
    font-family: var(--font0);
    font-size: 1em;
}

.form-submit {
    background-color: hsl(186, 80%, 50%);
    background-color: var(--feedcolor);
    height: 40px;
    border-radius: 20px;
    border-radius: var(--borderradius0);
    border-style: none;
    font-size: 0.9em;
    font-weight: 600;
    color: #000000;
}

.form-message {
    margin: 5px 0 0 0;
    color: hsl(0, 0%, 85%);
    color: var(--white);
    font-family: 'Source Sans 3', sans-serif;
    font-family: var(--font0);
    font-size: 1em;
}

.text-white {
    color: hsl(0, 0%, 85%);
    color: var(--white);
}

.text-subwhite {
    color: hsl(0, 0%, 65%);
    color: var(--subwhite);
}

.text-green {
    color: hsl(151, 80%, 45%);
    color: var(--conceptcolor);
}

.text-blue {
    color: hsl(186, 80%, 50%);
    color: var(--feedcolor);
}

.text-purple {
    color: hsl(271, 80%, 75%);
    color: var(--sourcecolor);
}

.text-bold {
    font-weight: 600;
}

.text-italics {
    font-style: italic;
}

.welcome-close-button {
    position: absolute;
    right: 15px;
    font-family: 'Material Icons';
    font-size: 1.5em;
    cursor: pointer;
}
